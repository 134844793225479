import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Typography } from "@material-ui/core"
import Layout from "../components/layout/layout-home"
import TitledContentWrapper from "../components/layout/titled-content-wrapper"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  content: {
    paddingBottom: "50px",
    maxWidth: "1000px",
    margin: "auto",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "16px",
      paddingRight: "16px",
    },
  },
}))

const TermsAndConditionsPage = () => {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    {
      strapi {
        termsConditionsPage {
          TermsConditionsContent
          Title
        }
      }
    }
  `)

  return (
    <Layout>
      <TitledContentWrapper title={data.strapi.termsConditionsPage.Title}>
        <Typography variant="body1" className={classes.content}>
          {/* <ReactMarkdown
            source={data.strapi.termsConditionsPage.TermsConditionsContent}
          /> */}

          <p>
            <strong>Who are we?</strong>
          </p>

          <p>
            This www.digicgroup.com website (“<strong>Site</strong>”) is owned
            and operated by <strong>Digic Pictures Kft.</strong> (a business
            organization registered in Hungary, registered seat: H-1031
            Budapest, Záhony u. 7, company registration number: 01-09-693165,
            contact e-mail address:&nbsp; privacy@digicpictures.com) („
            <strong>Company</strong>”)
          </p>

          <p>
            In these Terms of Use, references to “we”, “us” or “our” means the
            Company, and as the case may be, their subsidiaries or affiliated
            companies. References to “you” and “your” are to users of our Site.
          </p>

          <p>
            <strong>What are these Terms of Use? </strong>
          </p>

          <p>
            This document sets out the guidelines and terms on which we offer
            you access to and use of the Site. Before starting to use the Site,
            please read these Terms of Use carefully.
          </p>

          <p>
            This Site does not use any cookie or other type of technology which
            may collect information from your computer or similar devices.
          </p>

          <p>
            <strong>Acceptance of this Terms of Use</strong>
          </p>

          <p>
            By using this Site you accept the Terms of Use. If you do not agree
            with the terms and conditions specified herein, please do not use
            this Site anymore. In this case you should leave the Site
            immediately.
          </p>

          <p>
            <strong>Content and availability of the Site</strong>
          </p>

          <p>
            We operate the Site with great care. &nbsp;However, the correctness
            and accuracy of the information available on the Site cannot be
            guaranteed.
          </p>

          <p>
            We take reasonable steps and do our best to ensure the proper
            operation of the Site, however we cannot guarantee that it is
            available in each day in 24 hours (7/24). Furthermore, we take
            appropriate steps to detect computer viruses but we cannot guarantee
            that our Site is free from malfunctions, defects, bugs and viruses;
            or that the Site will operate correctly and as expected, at any
            given time.
          </p>

          <p>
            We shall not be liable for any loss or damage, which occurs as a
            result of any virus, or other technologically harmful material that
            may infect your computer equipment, computer programs, data or other
            materials.
          </p>

          <p>
            THE SITE AND ITS CONTENT ARE AVAILABLE ON AN "AS IS" BASIS WITHOUT
            WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING,
            WITHOUT LIMITATION, WARRANTIES OF TITLE OR IMPLIED WARRANTIES OF
            MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. YOU HEREBY
            ACKNOWLEDGE THAT USE OF THE SITE IS AT YOUR SOLE RISK.
          </p>

          <p>
            We may modify, update or withdraw the content of the Site or any of
            its elements anytime without prior notification.
          </p>

          <p>
            The Site may contain links to other related internet sites,
            resources, and advertisers. We do not review or monitor web sites or
            contents linked to this Site. We are not responsible for the content
            or availability of these outside resources, and shall not be liable
            for damages arising from the use of such websites. After you click
            the link, we have no longer any influence or control over, thus we
            are not responsible for the collection or handling of personal data
            or the activity of the site operators. If you have any concerns
            regarding these external links and websites, please direct them to
            the operator of the respective website.
          </p>

          <p>
            <strong>Copyright</strong>
          </p>

          <p>All rights are reserved.</p>

          <p>
            The layout of the Site, text, sounds, videos, graphics, pictures,
            articles, trademarks and logos, terms of use – including this Terms
            of Use – or any other type of arrangements and any and all other
            materials and creative works available on the Site are protected by
            copyright or other intellectual property rights and owned or
            licensed by us.
          </p>

          <p>
            Subject to the exception set out below, neither this Site nor any
            part of it may be copied, reproduced, republished, extracted,
            modified, distributed, or reutilised in any form, without the prior
            written permission as set forth below.
          </p>

          <p>
            You may download or copy the Site or any content or downloadable
            items displayed on the Site for personal use only. In this event,
            you shall name the source and where the copy was taken from and
            maintain all copyright and other notices contained therein.
          </p>

          <p>
            You shall not use any content or material or any part of it, which
            are held on the Site or made available through it for commercial or
            business purposes without obtaining a licence to do so. The license
            shall be obtained either from us or from our licensors as it
            indicated in the respective copyright notice. If it is not stated
            otherwise in the copyright notice we are the rightful owner of the
            respective content and please contact us for obtaining the necessary
            permission or license. Requests for permission should be addressed
            to the contact as indicated above.
          </p>

          <p>
            <strong>How to use the Site</strong>
          </p>

          <p>
            Linking or framing this Site are not permitted without the prior and
            express written consent of us.
          </p>

          <p>
            We may at any time limit your access to the Site or some part or
            features of the Site as appropriate or necessary at our sole
            discretion for security or business reasons, including but not
            limited to protect our intellectual property or as a result of any
            alleged or suspected breach of these Terms of Use.
          </p>

          <p>
            You are responsible for configuring your computer programmes,
            devices, applications and platform in order to access the Site and
            for protecting these with your own anti-virus software, firewalls
            and any other technical measures. We give no warranties as to the
            compatibility of our Site with your information technology.
          </p>

          <p>
            You shall not misuse our Site by knowingly introducing viruses,
            trojans, worms, logic bombs, keystroke loggers, spyware, adware,
            and/or any other harmful programs or similar computer code designed
            to adversely affect the operation of any computer software or
            hardware, or which is otherwise malicious or technologically
            harmful.
          </p>

          <p>
            You must not attack our Site via a denial-of-service attack or a
            distributed denial-of service attack, or in any other way. By
            breaching this provision, you would commit a criminal offence.
          </p>

          <p>
            <strong>Miscellaneous</strong>
          </p>

          <p>
            We reserve the right to amend and/or revise these Terms of Use at
            our discretion at any time. All changes will become effective
            immediately unless otherwise stated. Your continued use of the Site
            following the posting of changes to these Terms of Use will mean you
            accept those changes.
          </p>

          <p>
            Should one or more provisions of these Terms of Use be or become
            invalid or unenforceable in whole or in part, this shall not affect
            the validity and enforceability of the remaining provisions of these
            Terms of Use. In place of any provisions which are invalid or not
            incorporated in these Terms of Use the relevant statutory provisions
            shall apply.
          </p>

          <p>
            These Terms of Use have been made in and shall be construed and
            enforced in accordance with the laws of Hungary. Any action to
            enforce these Terms of Use shall be brought in the court of Hungary,
            which has competency and authority under the applicable Civil
            Procedure Act.
          </p>
        </Typography>
      </TitledContentWrapper>
    </Layout>
  )
}

export default TermsAndConditionsPage
